const angular = require("angular");
const swal = require("sweetalert");

angular.module('reg')
    .controller('ApplicationCtrl', [
        '$scope',
        '$rootScope',
        '$state',
        '$http',
        'currentUser',
        'settings',
        'Session',
        'UserService',
        'Upload',
        function ($scope, $rootScope, $state, $http, currentUser, settings, Session, UserService, Upload) {

            // Set up the user
            $scope.user = currentUser.data;

            // Is the student from MIT?
            $scope.isMitStudent = $scope.user.email.split('@')[1] == 'mit.edu';

            // If so, default them to adult: true
            if ($scope.isMitStudent) {
                $scope.user.profile.adult = true;
            }

            // Populate the school dropdown
            populateSchools();
            _setupForm();

            $scope.regIsClosed = Date.now() > settings.data.timeClose;

            /**
             * TODO: JANK WARNING
             */
            function populateSchools() {
                $http
                    .get('/assets/schools.json')
                    .then(function (res) {
                        var schools = res.data;
                        var email = $scope.user.email.split('@')[1];

                        if (schools[email]) {
                            $scope.user.profile.school = schools[email].school;
                            $scope.autoFilledSchool = true;
                        }
                    });

                $http
                    .get('/assets/schools.csv')
                    .then(function (res) {
                        $scope.schools = res.data.split('\n');
                        $scope.schools.push('Other');

                        var content = [];

                        for (i = 0; i < $scope.schools.length; i++) {
                            $scope.schools[i] = $scope.schools[i].trim();
                            content.push({ title: $scope.schools[i] })
                        }

                        $('#school.ui.search')
                            .search({
                                source: content,
                                cache: true,
                                onSelect: function (result, response) {
                                    $scope.user.profile.school = result.title.trim();
                                }
                            })
                    });
            }

            function _uploadResume(callback) {
                // getResumeUploadRequest returns a signed S3 upload key
		console.log("UPLOADING RESUME");
                UserService.getResumeUploadRequest().then(
                    function success(res) {
                        var data = res.data;
                        data.fields.file = $scope.resume;
			console.log(data.host);
                        Upload.upload({
                            url: data.host,
                            method: 'POST',
                            data: data.fields
                        }).success(function () {
                            $scope.hasUploadedResume = true;
                            $('#saving-modal').modal('hide');
                            callback();
                        }).error(function (res) {
                            $('#saving-modal').modal('hide');
                            swal("Uh oh!", "We couldn't upload your resume.", "error");
                        });
                    },
                    function error(res) {
                        $('#saving-modal').modal('hide');
                        swal("Uh oh!", "We couldn't upload your resume.", "error");
                    }
                );
            }

            function _updateUser(e) {
                UserService
                    .updateProfile(Session.getUserId(), $scope.user.profile)
                    .then(response => {
                        swal("Awesome!", "Your application has been saved!", "success").then(value => {
                            $state.go("app.dashboard");
                        });
                    }, response => {
                        swal("Uh oh!", "Something went wrong.", "error");
                    });
            }

            function checkHasResume() {
                // Try requesting the users resume on page load to check if it has been uploaded
                const url = "https://s3.amazonaws.com/makemit-resumes-2021/" + $scope.user.profile.resumeName;
                $http.get(url).then(function (r) {
                    // success
                    $scope.hasUploadedResume = true;
                }, function (r) {
                    // error (resume does not exist)
                });
            }
            checkHasResume();

            function isMinor() {
                return !$scope.user.profile.adult;
            }

            function minorsAreAllowed() {
                return settings.data.allowMinors;
            }

            function minorsValidation() {
                // Are minors allowed to register?
                if (isMinor() && !minorsAreAllowed()) {
                    return false;
                }
                return true;
            }

            function _setupForm() {
                // Custom minors validation rule
                $.fn.form.settings.rules.allowMinors = function (value) {
                    return minorsValidation();
                };

                // Semantic-UI form validation
                $('.ui.form').form({
                    fields: {
                        name: {
                            identifier: 'name',
                            rules: [
                                {
                                    type: 'empty',
                                    prompt: 'Please enter your name.'
                                }
                            ]
                        },
                        school: {
                            identifier: 'school',
                            rules: [
                                {
                                    type: 'empty',
                                    prompt: 'Please enter your school name.'
                                }
                            ]
                        },
                        graduationYear: {
                            identifier: 'graduationYear',
                            rules: [
                                {
                                    type: 'empty',
                                    prompt: 'Please select your graduation year.'
                                }
                            ]
                        },
                        gender: {
                            identifier: 'gender',
                            rules: [
                                {
                                    type: 'empty',
                                    prompt: 'Please select a gender.'
                                }
                            ]
                        },
                        major: {
                            identifier: 'major',
                            rules: [
                                {
                                    type: 'empty',
                                    prompt: 'Please select a major.'
                                }
                            ]
                        },
                        /*
                        resume: {
                            identifier: 'resume',
                            rules: [
                                {
                                    type: 'empty',
                                    prompt: 'Please upload a resume.'
                                }
                            ]
                        },
                        */
                        adult: {
                            identifier: 'adult',
                            rules: [
                                {
                                    type: 'allowMinors',
                                    prompt: 'You must be an adult, or an MIT student.'
                                }
                            ]
                        },
                        // Application questions
                        essay1: {
                            identifier: 'essay1',
                            rules: [
                                {
                                    type: 'empty',
                                    prompt: 'Please answer.'
                                }
                            ]
                        },
                        essay2: {
                            identifier: 'essay2',
                            rules: [
                                {
                                    type: 'empty',
                                    prompt: 'Please answer.'
                                }
                            ]
                        },
                        essay3: {
                            identifier: 'essay3',
                            rules: [
                                {
                                    type: 'empty',
                                    prompt: 'Please answer.'
                                }
                            ]
                        },
                    }
                });
	    }; 

            // For multi-select dropdown for majors
            $('.ui.dropdown').dropdown();
            $('.ui.dropdown').dropdown({ allowAdditions: true });
            $('.ui.dropdown').dropdown('set selected', $scope.user.profile.major);
	    
	
            // For hardware suggestions
            $('.ui.suggestions').dropdown();
            $('.ui.suggestions').dropdown({ allowAdditions: true });
            $('.ui.suggestions').dropdown('set selected', $scope.user.profile.hardware);
            $scope.submitForm = function () {
                var isValid = $('.ui.form').form('is valid'); // doesn't check resume because they could have uploaded it before
                var resumeIsValid = ($scope.resume) || $scope.hasUploadedResume;

                if (isValid && resumeIsValid) {
                    if ($scope.resume) {
                        if ($scope.resume.size > 4000000) {
                            swal("Uh oh!", "Your resume is too big!", "error");
                            return;
                        }
                        $('#saving-modal').modal('show');
                        //_updateUser();
                        _uploadResume(_updateUser);
                    } else {
                        _updateUser();
                    }
                } else {
                    swal(":(", "Please Fill In The Required Fields", "error");
                }
            };

        }]);
