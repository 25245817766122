angular.module('reg')
  .controller('ConfirmationCtrl', [
    '$scope',
    '$rootScope',
    '$state',
    'currentUser',
    'Utils',
    'UserService',
    '$http',
    'Session',
    'Upload',
    function($scope, $rootScope, $state, currentUser, Utils, UserService, $http, Session, Upload){

      // Set up the user
      var user = currentUser.data;
      $scope.user = user;

      $scope.pastConfirmation = false;

      $scope.formatTime = Utils.formatTime;

      _setupForm();

      $scope.user.confirmation.fileName = user._id + "_" + user.profile.name.split(" ").join("_");

	$('.ui.fields').dropdown();
	$('.ui.fields').dropdown({allowAdditions: true});
	$('.ui.fields').dropdown('set selected', $scope.user.confirmation.teamRecc.teamFields);
      // -------------------------------
      // All this just for dietary restriction checkboxes fml

      var dietaryRestrictions = {
        'Vegetarian': false,
        'Vegan': false,
        'Halal': false,
        'Kosher': false,
        'Nut Allergy': false
      };

      if (user.confirmation.dietaryRestrictions){
        user.confirmation.dietaryRestrictions.forEach(function(restriction){
          if (restriction in dietaryRestrictions){
            dietaryRestrictions[restriction] = true;
          }
        });
      }

      $scope.dietaryRestrictions = dietaryRestrictions;

      // -------------------------------

      function _uploadResume(callback) {
                // getResumeUploadRequest returns a signed S3 upload key
                UserService.getResumeUploadRequest().then(
                    function success(res) {
                        var data = res.data;
                        data.fields.file = $scope.resume;
                        Upload.upload({
                            url: data.host,
                            method: 'POST',
                            data: data.fields
                        }).success(function () {
                            $scope.hasUploadedResume = true;
                            $('#saving-modal').modal('hide');
                            callback();
                        }).error(function (res) {
                            $('#saving-modal').modal('hide');
                            swal("Uh oh!", "We couldn't upload your resume.", "error");
                        });
                    },
                    function error(res) {
                        $('#saving-modal').modal('hide');
                        swal("Uh oh!", "We couldn't upload your resume.", "error");
                    }
                );
            }
            
            function checkHasResume() {
                // Try requesting the users resume on page load to check if it has been uploaded
                const url = "https://s3.amazonaws.com/makemit-resumes-2020/" + $scope.user.profile.resumeName;
                $http.get(url).then(function (r) {
                    // success
                    $scope.hasUploadedResume = true;
                    console.log("resume had")
                }, function (r) {
                    // error (resume does not exist)
                });
            }
            checkHasResume();


            $scope.submitForm = function () {
                var isValid = $('.ui.form').form('is valid'); // doesn't check resume because they could have uploaded it before
                var resumeIsValid = ($scope.resume) || $scope.hasUploadedResume;
                if  (resumeIsValid && isValid) {
                    if ($scope.resume) {
                        if ($scope.resume.size > 4000000) {
                            swal("Uh oh!", "Your resume is too big!", "error");
                            return;
                        }
                        $('#saving-modal').modal('show');
                        _uploadResume(_updateUser);
                    } else {
                        _updateUser();
                    }
                } else {
                    swal(":(", "Please Fill In The Required Fields", "error");
                }
            };

    


      function _updateUser(e){
        var confirmation = $scope.user.confirmation;
        // Get the dietary restrictions as an array
        var drs = [];
        Object.keys($scope.dietaryRestrictions).forEach(function(key){
          if ($scope.dietaryRestrictions[key]){
            drs.push(key);
          }
        });
        confirmation.dietaryRestrictions = drs;

        UserService
          .updateConfirmation(Session.getUserId(), confirmation)
          .then(response =>{
                swal("Awesome!", "You are confirmed!.", "success").then(value => {
                            $state.go("app.dashboard");
                        });
          })
          .catch(function(res){
            console.log(res)
            sweetAlert("Uh oh!", "Something went wrong. Please email team@makemit.org if it persists.", "error");
          });
      }
      function _setupForm(){
        // Semantic-UI form validation
        $('.ui.form').form({
          fields: {
            shirt: {
              identifier: 'shirt',
              rules: [
                {
                  type: 'empty',
                  prompt: 'Please give us a shirt size!'
                }
              ]
            },
            phone: {
              identifier: 'phone',
              rules: [
                {
                  type: 'empty',
                  prompt: 'Please enter a phone number.'
                }
              ]
            },
            waivers: {
              identifier: 'waivers',
              rules: [
                {
                  type: 'empty',
                  prompt: 'You must agree to our liability waiver, photo release, and code of conduct, which will be sent out on a later date.'
                }
              ]
            },
            signature: {
              identifier: 'signature',
              rules: [
                {
                  type: 'empty',
                  prompt: 'You have to provide a signature to confirm you agree with our waivers.'
                }
              ]
            },
            team: {
              identifier: 'team',
              rules: [
                {
                  type: 'empty',
                  prompt: 'Please tell us whether you want us to help match you with a team!'
                }
              ]
            },

          }
        });
      }

      

    }]);
